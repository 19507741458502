import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import NewsletterSignup from "../newsletter-signup"
import { FooterMenu1 } from "./footer-menu-1"
import { FooterMenu2 } from "./footer-menu-2"
import { FooterMenu3 } from "./footer-menu-3"
import Link from "../link"

const Footer = () => (
  <footer>
    <NewsletterSignup />

    <div className="page-footer__content background-light">
      <div className="container py-4">
        <div className="text-center mb-3">
          <Link to="https://www.facebook.com/RobinHill.IW/">
            <span>
              <svg 
                  style={{ height: '30px', maxHeight: '30px', marginRight: '10px'}} 
                  aria-hidden="true" 
                  focusable="false" 
                  data-prefix="fab" 
                  data-icon="facebook-f" 
                  class="svg-inline--fa fa-facebook-f fa-w-10" 
                  role="img" 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 320 512">
                <path fill="white" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
              </svg>
            </span>
          </Link>
          <Link to="https://www.tiktok.com/@robinhillpark">
            <span>
            <svg style={{ height: '30px', maxHeight: '30px', marginRight: '10px', fill: '#fff'}}  xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 449.45 515.38"><path fill-rule="nonzero" d="M382.31 103.3c-27.76-18.1-47.79-47.07-54.04-80.82-1.35-7.29-2.1-14.8-2.1-22.48h-88.6l-.15 355.09c-1.48 39.77-34.21 71.68-74.33 71.68-12.47 0-24.21-3.11-34.55-8.56-23.71-12.47-39.94-37.32-39.94-65.91 0-41.07 33.42-74.49 74.48-74.49 7.67 0 15.02 1.27 21.97 3.44V190.8c-7.2-.99-14.51-1.59-21.97-1.59C73.16 189.21 0 262.36 0 352.3c0 55.17 27.56 104 69.63 133.52 26.48 18.61 58.71 29.56 93.46 29.56 89.93 0 163.08-73.16 163.08-163.08V172.23c34.75 24.94 77.33 39.64 123.28 39.64v-88.61c-24.75 0-47.8-7.35-67.14-19.96z"/></svg>
            </span>
          </Link>
          <Link to="https://www.instagram.com/robinhilladventurepark/">
          <span>
              <svg 
                  style={{ height: '30px', maxHeight: '30px', marginRight: '10px'}} 
                  aria-hidden="true" 
                  focusable="false" 
                  data-prefix="fab" d
                  ata-icon="instagram" 
                  class="svg-inline--fa fa-instagram fa-w-14" 
                  role="img" 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 448 512">
                <path fill="white" 
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                </path>
              </svg>
            </span>
          </Link>
          <Link to="https://www.youtube.com/channel/UC8m1WY8VcrO9eGI54gDhIMA">
            <span>
              <svg 
                style={{ height: '30px', maxHeight: '30px', marginRight: '10px' }} 
                aria-hidden="true" 
                focusable="false" 
                data-prefix="fab" 
                data-icon="youtube" 
                className="svg-inline--fa fa-youtube fa-w-14" 
                role="img" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 576 512">
                <path 
                  fill="white" 
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                ></path>
              </svg>
            </span>
          </Link>
        </div>

        <h2 className="text-uppercase text-center text-white mb-3">Share your adventures <span className="highlight">#ROBINHILL</span></h2>

        <div className="row">
          <div className="col col-md-4 text-center">
            <FooterMenu1 />
          </div>
          <div className="col col-md-4 text-center">
            <FooterMenu2 />
          </div>
          <div className="col col-md-4 text-center">
            <FooterMenu3 />
          </div>
        </div>

      </div>
    </div>

    <div className="bg-white">
      <div className="container py-4">
        <div className="row text-center">
          <p className="mt-4 mb-0"><small>Copyright {new Date().getFullYear()} &copy; All rights reserved. Developed by <a href="https://brightbulbdesign.co.uk" target="_blank" rel="noreferrer">Brightbulb Design</a>.</small></p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer