import React from "react"
import { useFooterMenu2 } from "../../hooks/use-footer-menu-2"
import Link from "../link"

export const FooterMenu2 = () => {
  const [menuItems] = useFooterMenu2()

  return (
    <ul className="list-unstyled">
      {menuItems.map((node) => {
        const { id = '', label = '', url = '' } = node
        return (
          <li key={id}>
            <Link 
              to={url}>
              {label}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}