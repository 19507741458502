import React, {useEffect, useState} from "react"
import rhPopUpImage from '../assets/images/rh-main-3.jpg'
import {navigate} from "gatsby"

const PopUp = ({}) => {
    const [popUpOpen, setPopUpOpen] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setPopUpOpen(true)
            document.body.classList.add('pop-up-open')
        }, 10000)
        return () => clearTimeout(timer);
    }, [])

    const closePopUp = () => {
        setPopUpOpen(false)
        document.body.classList.remove('pop-up-open')
    }

    const followLinks = () => {
        closePopUp()
        navigate("/plan-your-visit/frequent-visitor-passes/")
    }

    return (
        <React.Fragment>
            {popUpOpen &&
                <div className="pop-up-overlay" role="dialog" aria-modal="true" data-headlessui-state="open">
                    <div className="pop-up-container bg-primary">
                        <div className="pop-up-header">
                            <a className="pop-up-close" onClick={closePopUp}>x</a>
                        </div>
                        <div className="pop-up-content text-white">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={rhPopUpImage} className="pop-up-image" width="224" height="89" alt="Robin Hill FVP Popup"></img>
                                </div>
                                <div className="col-12 col-md-6 pb-4 pb-md-1 pop-up-text text-center d-flex flex-column justify-content-center">
                                    <h4>Sign up to our mailing list to be the first to know when our 2024 Frequent Visitor Passes go on sale</h4>

                                    <a onClick={followLinks} className="btn btn-secondary text-primary mt-3">SIGN UP AND SAVE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default PopUp