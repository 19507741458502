import React, { useRef, useState } from "react"
import Link from "../components/link"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { useHeaderMenu } from "../hooks/use-header-menu"

import LogoImg from "../assets/images/RobinHill_Logo.png"

const Navigation = () => {
  const navButtonRef = useRef(null)
  const navContainerRef = useRef(null)
  const [headerMenuItems] = useHeaderMenu()
  const [activeSubMenu, setActiveSubMenu] = useState(null)
  const [activeMenuItem, setActiveMenuItem] = useState(null)

  const onToggleClick = (e) => {
    e.preventDefault()
    document.body.classList.toggle("no-scroll")
    navContainerRef.current.classList.toggle("show")
    navButtonRef.current.classList.toggle("close")
  }

  const navClick = (e) => {
    if(e.target.classList.contains('dropdown-toggle')) {
      e.preventDefault()
      return
    }
    closeNav()
  }

  const closeNav = () => {
    document.body.classList.remove("no-scroll")
    navContainerRef.current.classList.remove("show")
    navButtonRef.current.classList.remove("close")
    setActiveSubMenu(null)
    setActiveMenuItem(null)
  }

  const toggleSecondTier = (itemId, e) => {
    e.preventDefault()
    e.stopPropagation()
    setActiveMenuItem(activeMenuItem === itemId ? null : itemId)
    if (activeMenuItem !== itemId) {
      setActiveSubMenu(null)
    }
  }

  const toggleThirdTier = (itemId, hasUrl, url, e) => {
    e.preventDefault()
    e.stopPropagation()
    if (hasUrl && url) {
      navigate(url)
    } else {
      setActiveSubMenu(activeSubMenu === itemId ? null : itemId)
    }
  }
  
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (navContainerRef.current && !navContainerRef.current.contains(event.target)) {
        setActiveMenuItem(null)
        setActiveSubMenu(null)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  
  const data = useStaticQuery(graphql`{
    allWpMenuItem(
      filter: {locations: {eq: PRIMARY_MENU}, parentDatabaseId: {eq: 0}}
      sort: {order: ASC}
    ) {
      edges {
        node {
          id
          title
          label
          childItems {
            nodes {
              label
              url
              id
              childItems {
                nodes {
                  label
                  url
                  id
                }
              }
            }
          }
          url
          cssClasses
        }
      }
      totalCount
    }
  }`)

  const renderThirdTierItems = (items, parentId) => {
    if (!items || items.length === 0) return null;
    
    return (
      <ul className={`third-tier-menu ${activeSubMenu === parentId ? 'show' : ''}`}
          style={{ pointerEvents: 'auto' }}>
        {items.map((thirdTierItem) => (
          <li key={thirdTierItem.id} className="nav-item third-tier-item">
            <Link 
              onClick={(e) => {
                navClick(e)
                closeNav() 
              }} 
              to={thirdTierItem.url} 
              className="dropdown-item" 
              activeClassName="active"
            >
              {thirdTierItem.label}
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  const renderSecondTierItems = (items, parentId) => {
    if (!items || items.length === 0) return null;

    return (
      <ul className={`dropdown-menu text-center text-lg-start ${activeMenuItem === parentId ? 'show' : ''}`}
          style={{ 
            display: activeMenuItem === parentId ? 'block' : 'none',
            pointerEvents: 'auto'
          }}>
        {items.map((secondTierItem) => {
          const hasChildren = secondTierItem.childItems?.nodes?.length > 0;
          return (
            <li 
              key={secondTierItem.id} 
              className={`dropdown nav-item second-tier-item ${hasChildren ? 'has-submenu' : ''}`}
            >
              <Link 
                onClick={(e) => {
                  toggleThirdTier(secondTierItem.id, !hasChildren, secondTierItem.url, e)
                  if (!hasChildren) {
                    closeNav()
                  }
                }}
                to={secondTierItem.url} 
                className="second-tier-wrapper"
                style={{ cursor: 'pointer' }}
              >
                <span className="dropdown-item">
                  {secondTierItem.label}
                </span>
                {hasChildren && (
                  <svg 
                    className="toggle-icon" 
                    width="12" 
                    height="12" 
                    viewBox="0 0 12 12"
                  >
                    <path d="M6 9L0 3h12z" fill="#fff"/>
                  </svg>
                )}
              </Link>
              {renderThirdTierItems(secondTierItem.childItems?.nodes, secondTierItem.id)}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <React.Fragment>
      <button 
        ref={navButtonRef} 
        onClick={onToggleClick} 
        className="nav-icon d-xl-none mr-3" 
        type="button" 
        aria-label="Toggle navigation"
      >
        <div></div>
      </button>
              
      <div ref={navContainerRef} className="collapse navbar-collapse align-items-stretch text-center">
        <img src={LogoImg} className="my-3 d-lg-none" height="80" alt="Robin Hill Logo" />
        
        <ul className="navbar-nav ms-auto text-center" style={{ pointerEvents: 'none' }}>
          {data.allWpMenuItem.edges.map((item) => (
            <li 
              className={`nav-item d-lg-table align-self-lg-stretch ${
                item.node.childItems?.nodes?.length > 0 ? "dropdown" : ""
              } ${item.node.cssClasses}`} 
              key={item.node.id}
              style={{ pointerEvents: 'auto' }}
            >
              <Link 
                onClick={(e) => item.node.childItems?.nodes?.length > 0 ? toggleSecondTier(item.node.id, e) : navClick(e)} 
                to={item.node.url} 
                className={`nav-link d-lg-table-cell align-middle${
                  item.node.childItems?.nodes?.length > 0 ? " dropdown-toggle" : ""
                }`} 
                data-bs-toggle={item.node.childItems?.nodes?.length > 0 ? "dropdown" : ""}
                style={{ pointerEvents: 'auto' }}
              >
                {item.node.label}
              </Link>
              {renderSecondTierItems(item.node.childItems?.nodes, item.node.id)}
            </li>
          ))}
          <li className="nav-item d-lg-table align-self-lg-stretch highlight" style={{ pointerEvents: 'auto' }}>
            <a 
              href="https://robinhill.byretail.net/store" 
              target="_blank" 
              rel="noreferrer" 
              className="nav-link d-lg-table-cell align-middle"
              onClick={closeNav}
            >
              Tickets & Passes
            </a>
          </li>
        </ul>

        <div className="d-lg-none px-4">
          <ul className="list-unstyled mt-4 text-left">
            {headerMenuItems.map((node) => {
              const { id = '', label = '', url = '' } = node
              return (
                <li className="mb-2" key={id}>
                  <Link
                    onClick={navClick}
                    className="text-white text-decoration-none" 
                    to={url}
                  >
                    {label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navigation