import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"

import Navigation from "./navigation"
import LogoImg from "../assets/images/RobinHill_Logo.png"

const Header = () => {
  const headerRef = useRef(null)

  useEffect(() => {
    const headerHeight = headerRef.current.clientHeight
    
    const shrinkHeader = function() {
      const distanceY = document.documentElement.scrollTop - headerHeight
      const shrinkOn = 15

      if(headerRef && headerRef.current !== undefined && headerRef.current !== null) {
        if (distanceY > shrinkOn) {
          headerRef.current.classList.add('shrunk')
        } else {
          if (distanceY < shrinkOn - headerHeight) {
            headerRef.current.classList.remove('shrunk')
          }
        }
      }
    }

    window.addEventListener("scroll", shrinkHeader, false)
  }, [])

  return (
    <header ref={headerRef} className="sticky-top shadow rb-header">
      <div className="background-light">
        <nav className="navbar navbar-expand-lg">
          <div className="container flex-wrap align-items-center align-items-lg-stretch justify-content-between">
            <Link to="/" className="navbar-logo">
              <img src={LogoImg} className="header__logo py-2" width="224" height="89" alt="Robin Hill Logo"></img>
            </Link>
            
            <Navigation />
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header