import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "./header"
import Footer from "./footer/footer"
import OpeningTimes from "./opening-times"
import NoticeBanner from "./notice-banner"
import CookieConsentBanner from "./cookie-consent-banner"
import PopUp from "./pop-up"
import HeaderNavigation from "./header-navigation"

import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';

const Layout = ({children}) => {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            twitter {
              username
            }
            youTube {
              url
            }
          }
        }
      }
    }
`);

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <Helmet>
        <meta name="facebook-domain-verification" content="h2cfrwnn865avllrco0739dw9502jx" />
      </Helmet>
      <>
        <div className="opening-times background-dark">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <OpeningTimes />
              <HeaderNavigation />
            </div>
          </div>
        </div>
        
        <NoticeBanner />
        <Header />
        {children}
        <Footer />
        <CookieConsentBanner />
          { process.env.GATSBY_POPUP_ACTIVE === "1" && <PopUp /> }
      </>

    </SEOContext.Provider>
  )
}

export default Layout