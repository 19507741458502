import React from "react"
import { useFooterMenu1 } from "../../hooks/use-footer-menu-1"
import Link from "../link"

export const FooterMenu1 = () => {
  const [menuItems] = useFooterMenu1()

  return (
    <ul className="list-unstyled">
      {menuItems.map((node) => {
        const { id = '', label = '', url = '' } = node
        return (
          <li key={id}>
            <Link 
              to={url}>
              {label}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}