import React from "react"
import CookieConsent from "react-cookie-consent"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "@spencerwhyte/gatsby-plugin-gdpr-cookies"

const CookieConsentBanner = () => {
  const location = useLocation()

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      style={{ 
        background: 'var(--bs-primary)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      contentStyle={{
        maxWidth: '500px',
        textAlign: 'center',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto'
      }}
      buttonStyle={{ 
        background: 'var(--bs-secondary)', 
        color: 'var(--bs-primary)'
      }}
      onAccept={(byScroll) => {
        initializeAndTrack(location)
      }}
      cookieName="gatsby-gdpr-tracking">
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  )
}

export default CookieConsentBanner

