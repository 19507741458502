module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f6b18b6d6fa5b1dff8091bace070ee4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"debug":{"preview":true,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"url":"https://admin.robin-hill.com/graphql","presets":null,"html":{"useGatsbyImage":true,"generateWebpImages":true,"imageQuality":64,"createStaticFiles":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"generateAvifImages":false,"placeholderType":"dominantColor"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/@spencerwhyte/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-9TMYEYTXJR","cookieName":"gatsby-gdpr-tracking","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-5QLJF9VM","cookieName":"gatsby-gdpr-tracking","defaultDataLayer":{"platform":"gatsby"}},"facebookPixel":{"cookieName":"gatsby-gdpr-tracking"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
