import React from "react"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ children, to, className, activeClassName, partiallyActive, ...other }) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        className={className}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} target="_blank" rel="noreferrer" className={className} {...other}>
      {children}
    </a>
  )
}

export default Link