import React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = `${process.env.GATSBY_MAILCHIMP_URL}?u=${process.env.GATSBY_MAILCHIMP_U}&id=${process.env.GATSBY_MAILCHIMP_ID}`

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = (e) => {
    e.preventDefault()
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });
  }

  return (
    <div>
      { status === "sending" && <div className="text-white mb-3">sending...</div> }
      { status === "error" && <div className="text-white mb-3" dangerouslySetInnerHTML={{ __html: message }}/> }
      { status === "success" && <div className="text-white" dangerouslySetInnerHTML={{ __html: message }}/> }

      {status !== "success" &&
        <div className="input-group">
          <label className="visually-hidden" htmlFor="newsletterEmail">Email</label>
          <input 
            type="email" 
            className="form-control" 
            id="newsletterEmail" 
            placeholder="Email"
            ref={node => (email = node)}
          />
          <button onClick={submit} className="btn btn-secundary">Submit</button>
        </div>
      }
    </div>
  )
}

const NewsletterSignup = () => (
  <div className="page-footer__newsletter-signup background-light">
    <div className="container p-5 text-center">
        <p className="h2 mb-3">
          Be the first to hear about new events, offers and competitions<br/>by joining our mailing list!
        </p>
        <form className="row form-dark">
          <div className="col-12 col-md-6 offset-md-3">
            <MailchimpSubscribe 
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe({
                    ...formData,
                    'group[5231][2]': '1'
                  })}
                />
              )}
            />
          </div>
        </form>
    </div>
  </div>
)

export default NewsletterSignup