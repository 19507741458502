import { graphql, useStaticQuery } from "gatsby"

export const useHeaderMenu = () => {
  const { allWpMenu } = useStaticQuery(
    graphql`
      query HEADER_MENU {
        allWpMenu(filter: { slug: { eq: "header-menu" } }) {
          nodes {
            databaseId
            name
            count
            slug
            menuItems {
              nodes {
                id
                label
                url
                childItems {
                  nodes {
                    label
                    url
                    id
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const { nodes: [ node = {} ] } = allWpMenu
  const {
    menuItems: { nodes: menuItems = [] }
  } = node
  return [menuItems]
}