exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-calendar-js": () => import("./../../../src/templates/calendar.js" /* webpackChunkName: "component---src-templates-calendar-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-wp-category-collection-js": () => import("./../../../src/templates/wp-category/collection.js" /* webpackChunkName: "component---src-templates-wp-category-collection-js" */),
  "component---src-templates-wp-page-page-contact-js": () => import("./../../../src/templates/wp-page/page-contact.js" /* webpackChunkName: "component---src-templates-wp-page-page-contact-js" */),
  "component---src-templates-wp-page-page-entertainment-js": () => import("./../../../src/templates/wp-page/page-entertainment.js" /* webpackChunkName: "component---src-templates-wp-page-page-entertainment-js" */),
  "component---src-templates-wp-page-page-fullwidth-js": () => import("./../../../src/templates/wp-page/page-fullwidth.js" /* webpackChunkName: "component---src-templates-wp-page-page-fullwidth-js" */),
  "component---src-templates-wp-page-page-js": () => import("./../../../src/templates/wp-page/page.js" /* webpackChunkName: "component---src-templates-wp-page-page-js" */),
  "component---src-templates-wp-page-page-opening-js": () => import("./../../../src/templates/wp-page/page-opening.js" /* webpackChunkName: "component---src-templates-wp-page-page-opening-js" */),
  "component---src-templates-wp-page-page-schools-js": () => import("./../../../src/templates/wp-page/page-schools.js" /* webpackChunkName: "component---src-templates-wp-page-page-schools-js" */),
  "component---src-templates-wp-post-post-js": () => import("./../../../src/templates/wp-post/post.js" /* webpackChunkName: "component---src-templates-wp-post-post-js" */)
}

