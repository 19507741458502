import React, { useState, useEffect } from "react"
import axios from "axios"

const NoticeBanner = () => {
  const [noticeState, setNoticeState] = useState({
    show: false,
    title: "",
    message: ""
  })

  useEffect(() => {
    const getNotice = () => {
      axios.get(
        process.env.GATSBY_NOTICE_ENDPOINT
      ).then(function (response) {
        setNoticeState({
          show: response.data.show,
          title: response.data.title,
          message: response.data.message
        })
      }).catch(function (error) {
        console.log(error)
      });
    }
    getNotice()
  }, [])

  return (
    <React.Fragment>
        {noticeState.show &&
        <div className="alert alert-primary alert-dismissible mb-0 fade show d-flex align-items-center" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} class="bi flex-shrink-0 me-2" role="img" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
            </svg>
            <div>
                <strong className="me-2">{noticeState.title}</strong>
                <span>{noticeState.message}</span>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        }
    </React.Fragment>
  )
}

export default NoticeBanner